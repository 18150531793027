import React, { useState } from 'react';
import './CustomerForm.css'; // Create a CSS file for styling

const CustomerForm = () => {
  const [formData, setFormData] = useState({
    bpCode: '',
    bpName: '',
    add1: '',
    add2: '',
    city: '',
    state: '',
    pin: '',
    country: '',
    gstin: '',
    cpName: '',
    cpMobile: '',
    cpEmail: ''
  });

  const [isFormVisible, setFormVisible] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission, e.g., send data to the server
    console.log('Form data submitted:', formData);
  };

  return (
    <div className="customer-form-container">
      <h2>Customer Information</h2>
      <button
        className="dropdown-button"
        onClick={() => setFormVisible(!isFormVisible)}
      >
        {isFormVisible ? 'Hide Form Fields' : 'Show Form Fields'}
      </button>
      {isFormVisible && (
        <form onSubmit={handleSubmit} className="customer-form">
          <div>
            <label>BP Code:</label>
            <input type="text" name="bpCode" value={formData.bpCode} onChange={handleChange} required />
          </div>
          <div>
            <label>BP Name:</label>
            <input type="text" name="bpName" value={formData.bpName} onChange={handleChange} required />
          </div>
          <div>
            <label>Add 1:</label>
            <input type="text" name="add1" value={formData.add1} onChange={handleChange} required />
          </div>
          <div>
            <label>Add 2:</label>
            <input type="text" name="add2" value={formData.add2} onChange={handleChange} />
          </div>
          <div>
            <label>City:</label>
            <input type="text" name="city" value={formData.city} onChange={handleChange} required />
          </div>
          <div>
            <label>State:</label>
            <input type="text" name="state" value={formData.state} onChange={handleChange} required />
          </div>
          <div>
            <label>Pin:</label>
            <input type="text" name="pin" value={formData.pin} onChange={handleChange} required />
          </div>
          <div>
            <label>Country:</label>
            <input type="text" name="country" value={formData.country} onChange={handleChange} required />
          </div>
          <div>
            <label>GSTIN:</label>
            <input type="text" name="gstin" value={formData.gstin} onChange={handleChange} />
          </div>
          <div>
            <label>CP Name:</label>
            <input type="text" name="cpName" value={formData.cpName} onChange={handleChange} required />
          </div>
          <div>
            <label>CP Mobile:</label>
            <input type="tel" name="cpMobile" value={formData.cpMobile} onChange={handleChange} required />
          </div>
          <div>
            <label>CP Email:</label>
            <input type="email" name="cpEmail" value={formData.cpEmail} onChange={handleChange} required />
          </div>
          <button type="submit" className="submit-button">Submit</button>
        </form>
      )}
    </div>
  );
};

export default CustomerForm;
