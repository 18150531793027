import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import './Contract.css'; // Import the CSS file

import DashboardHeader from './DashboardHeader'; // Ensure this path is correct
import Sidebar from './Sidebar'; // Ensure this path is correct

const Contract = () => {
  const [contracts, setContracts] = useState([
    { id: 1, name: 'Contract A', date: '2024-01-01', status: 'Active' },
    { id: 2, name: 'Contract B', date: '2024-02-15', status: 'Inactive' },
  ]);

  const [newContractName, setNewContractName] = useState('');
  const [showAddContract, setShowAddContract] = useState(false);

  const handleAddContract = () => {
    if (newContractName) {
      const newContract = {
        id: contracts.length + 1,
        name: newContractName,
        date: new Date().toISOString().split('T')[0], // Current date
        status: 'Active',
      };
      setContracts([...contracts, newContract]);
      setNewContractName('');
      setShowAddContract(false);
    }
  };

  const handleDeleteContract = (id) => {
    setContracts(contracts.filter(contract => contract.id !== id));
  };

  const handleExportPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({ html: '#contractsTable' });
    doc.save('contracts.pdf');
  };

  const handleExportExcel = () => {
    const ws = XLSX.utils.json_to_sheet(contracts);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Contracts');
    XLSX.writeFile(wb, 'contracts.xlsx');
  };

  return (
    <div className="contract">
      <DashboardHeader />
      <Sidebar />

      <div className="contract-content">
        <h2>Contracts</h2>
        
        {/* Export Options */}
        <div className="export-buttons">
          <CSVLink data={contracts} filename="contracts.csv" className="export-button">
            📊 Export to CSV
          </CSVLink>
          <button onClick={handleExportPDF} className="export-button">
            📄 Export to PDF
          </button>
          <button onClick={handleExportExcel} className="export-button">
            📈 Export to Excel
          </button>
        </div>

        <button onClick={() => setShowAddContract(!showAddContract)}>
          {showAddContract ? 'Cancel' : 'Add Contract'}
        </button>

        {showAddContract && (
          <div>
            <input
              type="text"
              value={newContractName}
              onChange={(e) => setNewContractName(e.target.value)}
              placeholder="Enter contract name"
            />
            <button onClick={handleAddContract}>Submit</button>
          </div>
        )}

        <h3>All Contracts</h3>
        <table id="contractsTable">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Date</th>
              <th>Status</th>
              <th>Actions</th> {/* Add Actions column */}
            </tr>
          </thead>
          <tbody>
            {contracts.map((contract) => (
              <tr key={contract.id}>
                <td>{contract.id}</td>
                <td>{contract.name}</td>
                <td>{contract.date}</td>
                <td>{contract.status}</td>
                <td>
                  <button onClick={() => handleDeleteContract(contract.id)}>
                    🗑️ {/* Trash bin emoji */}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Contract;
