import React, { useState } from 'react';
import './Assets.css'; // Importing the CSS file
import Sidebar from './Sidebar';
import './Dashboard.css';
import { useNavigate } from 'react-router-dom';

const Assets = () => {
  const [assets] = useState([
    { id: 1, name: 'Projector', status: 'In Use', profitGenerated: 150, inRepair: false },
    { id: 2, name: 'Conference Table', status: 'Idle', profitGenerated: 0, inRepair: false },
    { id: 3, name: 'Laptop', status: 'Rented', profitGenerated: 300, inRepair: false },
    { id: 4, name: 'Whiteboard', status: 'Common Use', profitGenerated: 50, inRepair: false },
    { id: 5, name: 'Television', status: 'In Use', profitGenerated: 100, inRepair: false },
  ]);

  const [notification, setNotification] = useState('');
  const [selectedAsset, setSelectedAsset] = useState('');

  const handleNotifyRepair = (e) => {
    e.preventDefault();
    // Here you could send the notification to the backend or alert the relevant team
    console.log(`Notifying repair team about: ${selectedAsset}`);
    setNotification(`Repair team notified about: ${selectedAsset}`);
    setSelectedAsset(''); // Reset the selected asset
  };
  const navigate = useNavigate();

  const handleLogout = () => {
    // Here you can clear any authentication state if necessary
    navigate('/login'); // Redirect to login page
  };
  

  return (

    

      

      <div className="assets-list">
        <div className="assets">
      <header className="dashboard-header">
          <h1>Assets Dashboard</h1>
          <button className="logout-button" onClick={handleLogout}>
            Logout
          </button>
        </header>

        {assets.map((asset) => (
          <div key={asset.id} className={`asset ${asset.inRepair ? 'in-repair' : ''}`}>
            <h3>{asset.name}</h3>
            <p>Status: {asset.status}</p>
            <p>Profit Generated: 💲{asset.profitGenerated}</p>
            <button onClick={() => setSelectedAsset(asset.name)}>Report Issue</button>
          </div>
        ))}
      </div>

      {selectedAsset && (
        <div className="notification-panel">
          <h3>Notify Repair Team</h3>
          <p>Reporting issue with: {selectedAsset}</p>
          <form onSubmit={handleNotifyRepair}>
            <button type="submit">Notify</button>
          </form>
        </div>
      )}

      {notification && <p className="notification-message">{notification}</p>}
    </div>
  );
};

export default Assets;
