import React, { useState } from 'react';
import './BillingDetails.css';
import Sidebar from './Sidebar';
import CostDistributionGraph from './CostDistributionGraph'; // Correctly import the CostDistributionGraph component
import PaymentPopup from './PaymentPopup'; // Import the PaymentPopup component

const BillingDetails = () => {
  const [billingData, setBillingData] = useState({
    totalRevenue: 50000,
    repairCosts: 15000,
    operationalCosts: 20000,
    occupantPayments: [
      { name: 'Company A', amount: 1500, date: '2024-09-01' },
      { name: 'Company B', amount: 1200, date: '2024-09-05' },
      { name: 'Company C', amount: 1800, date: '2024-09-10' },
    ],
  });
  
  const [isPopupOpen, setPopupOpen] = useState(false);

  const handlePayNow = () => {
    setPopupOpen(true);
  };

  const handlePayment = (method) => {
    // Implement payment logic based on selected method
    switch (method) {
      case 'razorpay':
        // Add Razorpay payment integration here
        alert('Proceeding with Razorpay...');
        break;
      case 'googlePay':
        alert('Proceeding with Google Pay...');
        break;
      case 'netBanking':
        alert('Proceeding with Net Banking...');
        break;
      case 'upi':
        alert('Proceeding with UPI...');
        break;
      default:
        break;
    }
    setPopupOpen(false);
  };

  return (
    <div className="billing-details">
      <Sidebar />
      <div className="billing-content">
        <header className="dashboard-header">
          <h1>Billing Details</h1>
          <button className="logout-button" onClick={() => alert('Logout')}>
            Logout
          </button>
        </header>

        <div className="billing-sections">
          <div className="billing-summary">
            <h2>📊 Billing Summary</h2>
            <p>Total Revenue: 💲{billingData.totalRevenue}</p>
            <p>Repair Costs: 💲{billingData.repairCosts}</p>
            <p>Operational Costs: 💲{billingData.operationalCosts}</p>
          </div>

          <div className="payment-history">
            <h2>📅 Payment History</h2>
            <table>
              <thead>
                <tr>
                  <th>Occupant</th>
                  <th>Amount</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {billingData.occupantPayments.map((payment, index) => (
                  <tr key={index}>
                    <td>{payment.name}</td>
                    <td>💲{payment.amount}</td>
                    <td>{payment.date}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="graph-section">
            <h2>📊 Cost Distribution</h2>
            <CostDistributionGraph /> {/* Include the graph component */}
          </div>

          <div className="pay-section">
            <h2>💳 Make a Payment</h2>
            <button className="btn-pay-now" onClick={handlePayNow}>
              Pay Now
            </button>
          </div>
        </div>
      </div>

      {/* Include the Payment Popup */}
      <PaymentPopup 
        isOpen={isPopupOpen} 
        onClose={() => setPopupOpen(false)} 
        handlePayment={handlePayment} 
      />
    </div>
  );
};

export default BillingDetails;
