import React, { useState } from 'react';
import './Dashboard.css';
import Sidebar from './Sidebar';
import DashboardHeader from './DashboardHeader';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const Dashboard = () => {

  const [meetingRequests, setMeetingRequests] = useState(['📅 Meeting with John', '📞 Client call with HR']);

  const [newRequest, setNewRequest] = useState('');

  // Sample office data for the bar chart
  const officeData = [
    { roomNumber: '101', pcCount: 5, printerCount: 2 },
    { roomNumber: '102', pcCount: 7, printerCount: 1 },
    { roomNumber: '103', pcCount: 3, printerCount: 4 },
    { roomNumber: '104', pcCount: 6, printerCount: 3 },
    { roomNumber: '105', pcCount: 8, printerCount: 5 },
  ];

  // Prepare data for the bar chart
  const data = officeData.map(office => ({
    roomNumber: office.roomNumber,
    pcCount: parseInt(office.pcCount, 10), // Ensure the values are integers
    printerCount: parseInt(office.printerCount, 10),
  }));

  const handleAddRequest = () => {
    if (newRequest.trim() !== '') {
      setMeetingRequests(prevRequests => [...prevRequests, newRequest]);
      setNewRequest(''); // Clear the input after adding the request
    }
  };

  const handleAcceptRequest = (index) => {
    console.log(`Accepted request: ${meetingRequests[index]}`);
    setMeetingRequests(prevRequests => 
      prevRequests.filter((_, i) => i !== index) // Remove accepted request
    );
  };

  const handleDeclineRequest = (index) => {
    console.log(`Declined request: ${meetingRequests[index]}`);
    setMeetingRequests(prevRequests => 
      prevRequests.filter((_, i) => i !== index) // Remove declined request
    );
  };
  // Data for Total Rooms and Occupied Rooms
  const roomData = [
    { name: 'Rooms', total: 50, occupied: 30 },
  ];

  // Data for Profit and Maintenance Costs
  const financialData = [
    { name: 'Finance', profit: 100000, maintenance: 30000 },
  ];

  return (
    



    <div className="dashboard-container">
      <Sidebar />
      <DashboardHeader />{}
      
      <div className="main-content">
        

        

        <div className="analysis-section">


            
          <h2>Assets Analysis</h2>
          <div className="analysis-cards">
            <div className="card">Total Assets: 50</div>
            <div className="card">Occupied Rooms: 20</div>
            <div className="card">Available Rooms: 30</div>
          </div>
        </div>


        

        <div className="charts-section">
          {/* First Bar Chart: Rooms */}
          <div className="bar-chart-container">
            <h2>Total Rooms vs. Occupied Rooms</h2>
            <BarChart width={400} height={300} data={roomData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="total" fill="#8884d8" name="Total Rooms" />
              <Bar dataKey="occupied" fill="#82ca9d" name="Occupied Rooms" />
            </BarChart>


        {/* Office Assets Overview Bar Chart */}
        <div className="dashboard-content">
  <h2>Office Assets Overview</h2>
  <div className="bar-chart-container"> {/* New container for the bar chart */}
    <BarChart width={600} height={300} data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="roomNumber" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="pcCount" fill="#8884d8" />
      <Bar dataKey="printerCount" fill="#82ca9d" />
    </BarChart>
  </div>
</div>
</div>

        {/* Meeting Section */}
        <div className="meeting-section">
          <h3>Meeting Requests</h3>
          <div className="meeting-bucket">
            {meetingRequests.length > 0 ? (
              <ul>
                {meetingRequests.map((request, index) => (
                  <li key={index} className="meeting-request-item">
                    {request}
                    <button className="accept-button" onClick={() => handleAcceptRequest(index)}>Accept</button>
                    <button className="decline-button" onClick={() => handleDeclineRequest(index)}>Decline</button>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No meeting requests</p>
            )}
          </div>
          <input
            type="text"
            value={newRequest}
            onChange={(e) => setNewRequest(e.target.value)}
            placeholder="Add new meeting request"
          />
          <button onClick={handleAddRequest}>Add Request</button>
        </div>
          </div>
          </div>

</div>
  );
};

export default Dashboard;
