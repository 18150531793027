import React, { useState } from 'react';
import './ConferenceRooms.css'; // CSS file
import Sidebar from './Sidebar'; // Assuming you have a Sidebar component
import DatePicker from 'react-datepicker'; // You'll need to install this package for the calendar
import 'react-datepicker/dist/react-datepicker.css';
import DashboardHeader from './DashboardHeader';

const ConferenceRooms = () => {
  const [rooms, setRooms] = useState([
    { crCode: 'CR001', crDescription: 'Conference Room A', capacity: 20, facilities: 'Projector, Wi-Fi', bookings: [] },
    { crCode: 'CR002', crDescription: 'Conference Room B', capacity: 15, facilities: 'TV, Whiteboard', bookings: [] },
    { crCode: 'CR003', crDescription: 'Conference Room C', capacity: 30, facilities: 'Conference Call Setup', bookings: [] },
    { crCode: 'CR004', crDescription: 'Conference Room D', capacity: 25, facilities: 'Wi-Fi, Projector', bookings: [] },
  ]);

  const [selectedRoom, setSelectedRoom] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [availableTimes, setAvailableTimes] = useState([]);
  
  // State to hold new room details
  const [newRoom, setNewRoom] = useState({
    crCode: '',
    crDescription: '',
    capacity: ''
  });

  const handleRoomClick = (room) => {
    setSelectedRoom(room);
    setSelectedDate(null); // Reset date when a new room is selected
    setAvailableTimes([]); // Reset available times
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);

    // Mock data - Fetch available times for the selected room and date
    const times = [
      { start: '09:00', end: '10:00', available: true },
      { start: '11:00', end: '12:00', available: false },
      { start: '14:00', end: '15:00', available: true },
    ];
    setAvailableTimes(times);
  };

  // Handle input changes for new room
  const handleInputChange = (e) => {
    setNewRoom({ ...newRoom, [e.target.name]: e.target.value });
  };

  // Handle form submission to add a new conference room
  const handleSubmit = (e) => {
    e.preventDefault();
    setRooms([...rooms, newRoom]);
    setNewRoom({ crCode: '', crDescription: '', capacity: '' }); // Clear form
  };

  return (
    <div className="conference-rooms-page">
      <Sidebar />
      <DashboardHeader />
      <div className="main-content">
        <div className="conference-rooms">
          <h2>🏛️ Conference Rooms</h2>
          <p>Check availability and book conference rooms here.</p>

          <form onSubmit={handleSubmit} className="add-room-form">
            <h3>Add New Conference Room</h3>
            <div className="form-group">
              <label>CR Code:</label>
              <input type="text" name="crCode" value={newRoom.crCode} onChange={handleInputChange} required />
            </div>
            <div className="form-group">
              <label>CR Description:</label>
              <input type="text" name="crDescription" value={newRoom.crDescription} onChange={handleInputChange} required />
            </div>
            <div className="form-group">
              <label>Capacity:</label>
              <input type="number" name="capacity" value={newRoom.capacity} onChange={handleInputChange} required />
            </div>
            <button type="submit" className="btn-primary">Add Room</button>
          </form>

          <div className="rooms-table">
            {rooms.map((room, index) => (
              <div key={index} className="room" onClick={() => handleRoomClick(room)}>
                <h3>{room.crDescription}</h3>
                <p>CR Code: {room.crCode}</p>
                <p>Capacity: {room.capacity}</p>
                <p>Facilities: {room.facilities}</p>
              </div>
            ))}
          </div>

          {/* Popup calendar and available times for booking */}
          {selectedRoom && (
            <div className="room-popup">
              <h3>Book {selectedRoom.crDescription}</h3>
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                placeholderText="Select a date"
                dateFormat="yyyy-MM-dd"
              />
              {selectedDate && (
                <div className="available-times">
                  <h4>Available Times on {selectedDate.toDateString()}</h4>
                  {availableTimes.length > 0 ? (
                    availableTimes.map((time, i) => (
                      <div key={i} className={`time-slot ${time.available ? 'available' : 'booked'}`}>
                        {time.start} - {time.end} {time.available ? 'Available' : 'Booked'}
                      </div>
                    ))
                  ) : (
                    <p>No available times for this date.</p>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConferenceRooms;
