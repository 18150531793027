import React, { useState } from 'react';
import './Offices.css'; // Import the CSS file
import Sidebar from './Sidebar'; // Reuse the sidebar from Dashboard

const Offices = () => {
  const [offices, setOffices] = useState([]);
  const [newOffice, setNewOffice] = useState({
    officeCode: '',
    officeDescription: '',
    capacity: '',
  });

  // Handle form input change
  const handleInputChange = (e) => {
    setNewOffice({ ...newOffice, [e.target.name]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setOffices([...offices, newOffice]); // Add new office to the list
    setNewOffice({
      officeCode: '',
      officeDescription: '',
      capacity: '',
    }); // Clear form
  };

  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="main-content">
        <header className="dashboard-header">
          <h1>Office Management</h1>
        </header>
        <div className="office-form-section">
          <h2>Add New Office</h2>
          <form onSubmit={handleSubmit} className="office-form">
            <div className="form-group">
              <label>Office Code:</label>
              <input
                type="text"
                name="officeCode"
                value={newOffice.officeCode}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Office Description:</label>
              <input
                type="text"
                name="officeDescription"
                value={newOffice.officeDescription}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Capacity:</label>
              <input
                type="number"
                name="capacity"
                value={newOffice.capacity}
                onChange={handleInputChange}
                required
              />
            </div>
            <button type="submit" className="btn-primary">Add Office</button>
          </form>
        </div>

        <div className="office-list-section">
          <h2>Added Offices</h2>
          {offices.length > 0 ? (
            <div className="office-grid">
              {offices.map((office, index) => (
                <div className="office-card" key={index}>
                  <h4>{office.officeDescription}</h4>
                  <p>Code: {office.officeCode}</p>
                  <p>Capacity: {office.capacity}</p>
                </div>
              ))}
            </div>
          ) : (
            <p>No offices added yet.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Offices;
