import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import BillingDetails from './components/BillingDetails';
import EmployeeManagement from './components/EmployeeManagement';
import Assets from './components/Assets';
import ConferenceRooms from './components/ConferenceRooms';
import Offices from './components/Offices';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import Sidebar from './components/Sidebar';
import DashboardHeader from './components/DashboardHeader'; // Import DashboardHeader
import CustomerForm from './components/CustomerForm'; // Import CustomerForm
import OfficeForm from './components/OfficeForm'; // Import OfficeForm
import VisitorManagement from './components/VisitorManagement'; // Import VisitorManagement
import Transaction from './components/Transaction'; // Import Transaction
import Contract from './components/Contract'; // Import Contract
import Invoicing from './components/Invoicing'; // Import Invoicing
import Payment from './components/Payment'; // Import Payment
import Support from './components/Support'; // Import Support
import Booking from './components/Booking'; // Adjust the path if necessary

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Dashboard />} />
          <Route path="/billing" element={<><DashboardHeader /><Sidebar /><BillingDetails /></>} />
          <Route path="/employees" element={<><DashboardHeader /><Sidebar /><EmployeeManagement /></>} />
          <Route path="/assets" element={<><DashboardHeader /><Sidebar /><Assets /></>} />
          <Route path="/conference-rooms" element={<><DashboardHeader /><Sidebar /><ConferenceRooms /></>} />
          <Route path="/offices" element={<><DashboardHeader /><Sidebar /><Offices /></>} />
          <Route path="/dashboard" element={<><DashboardHeader /><Sidebar /><Dashboard /></>} />
          {/* Update the Booking route here */}
          <Route path="/booking" element={<><DashboardHeader /><Sidebar /><Booking /></>} />
          
          {/* Add routes for forms */}
          <Route path="/customer-form" element={<><DashboardHeader /><Sidebar /><CustomerForm /></>} />
          <Route path="/office-form" element={<><DashboardHeader /><Sidebar /><OfficeForm /></>} />
          <Route path="/visitor-management" element={<><DashboardHeader /><Sidebar /><VisitorManagement /></>} />
          <Route path="/transaction" element={<><DashboardHeader /><Sidebar /><Transaction /></>} />
          <Route path="/contract" element={<><DashboardHeader /><Sidebar /><Contract /></>} />
          <Route path="/invoicing" element={<><DashboardHeader /><Sidebar /><Invoicing /></>} />
          <Route path="/payment" element={<><DashboardHeader /><Sidebar /><Payment /></>} />
          <Route path="/support" element={<><DashboardHeader /><Sidebar /><Support /></>} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
