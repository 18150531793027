// DashboardHeader.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './DashboardHeader.css'; // Import any specific styles for the header

const DashboardHeader = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Here you can clear any authentication state if necessary
    navigate('/login'); // Redirect to login page
  };

  return (
    <header className="dashboard-header">
      <h1>Admin Dashboard</h1>
      <button className="logout-button" onClick={handleLogout}>
        Logout
      </button>
    </header>
  );
};

export default DashboardHeader;
